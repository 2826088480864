import { Component, OnInit } from '@angular/core';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-recent-work-bar',
  templateUrl: './recent-work-bar.component.html',
  styleUrls: ['./recent-work-bar.component.css']
})
export class RecentWorkBarComponent implements OnInit {

  public RecentWorkListingURLRoutes = HomeComponent.RecentWorkListingURLRoutes;
  
  constructor() { }

  ngOnInit(): void {
  }

}
