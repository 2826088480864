import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { RecentWorkComponent } from './components/recent-work/recent-work.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { RecentWorkListingComponent } from './components/recent-work-listing/recent-work-listing.component';
import { RecentWorkBarComponent } from './components/recent-work-bar/recent-work-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {HttpClientModule} from '@angular/common/http';
import { ThanksComponent } from './components/thanks/thanks.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RecentWorkComponent,
    ContactUsComponent,
    RecentWorkListingComponent,
    RecentWorkBarComponent,
    NavBarComponent,
    ThanksComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
