import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var require: any

@Component({
  selector: 'app-recent-work-listing',
  templateUrl: './recent-work-listing.component.html',
  styleUrls: ['./recent-work-listing.component.css']
})
export class RecentWorkListingComponent implements OnInit, OnDestroy {

  
  private ListingIDRequired : string = ""
  private RecentWorkListings : any[] = require('../../api-responses/recent-work-listings.json');

  constructor(private route : ActivatedRoute, private router : Router) {
   }

  ngOnInit(): void {

    this.ListingIDRequired = this.route.snapshot.url[1].path;
  }

  ngOnDestroy(){

  }

  public get ActiveListing() {
    return this.RecentWorkListings.find(w => w.JobURL == this.ListingIDRequired)
  }

  public ChangeMainImage(elementID : string, imageURL : string){
    console.log(elementID)
    let s : HTMLImageElement = <HTMLImageElement>document.getElementById(elementID)
    s.src = imageURL;
  }

}
