import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { RecentWorkListingComponent } from './components/recent-work-listing/recent-work-listing.component';
import { RecentWorkComponent } from './components/recent-work/recent-work.component';
import { ThanksComponent } from './components/thanks/thanks.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full'
  },
  {
    path: 'request-a-quote',
    component: ContactUsComponent,
    pathMatch: 'full'
  },
  {
    path: 'recent-work',
    component: RecentWorkComponent,
    pathMatch: 'full'
  },
  {
    path: 'recent-work/shrub-prunning',
    component: RecentWorkListingComponent,
    pathMatch: 'full'
  },
  {
    path: 'recent-work/landscape-design',
    component: RecentWorkListingComponent,
    pathMatch: 'full'
  },
  
  {
    path: 'recent-work/weekly-maintenance',
    component: RecentWorkListingComponent,
    pathMatch: 'full'
  },
  
  {
    path: 'recent-work/spring-and-fall-clean-up',
    component: RecentWorkListingComponent,
    pathMatch: 'full'
  },
  {
    path: 'thanks',
    component: ThanksComponent,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
