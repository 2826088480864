<main class="container">

    <div class="row my-4">
        <div class="col-11" style="padding-left: 0px;">
            <h3>REQUEST A QUOTE</h3>
            <p class="mb-2">
                Contact us via the contact form to find out how we can help meet your landscape needs.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-11 col-sm-12 py-3 h-50" style="background-color: #DBE6DF;">
            <div class="row mt-2">
                <div class="col-lg-4 col-sm-12 pt-2">
                    <label for="name" class="form-label">Name</label>
                    <input #name type="text" class="form-control" id="name">
                </div>
                <div class="col-lg-6 col-sm-12 pt-2">
                    <label for="email" class="form-label">Email address</label>
                    <input #email type="email" class="form-control" id="email" placeholder="name@example.com">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-sm-12 pt-2">
                    <label for="phone" class="form-label">Phone Number</label>
                    <input #phone type="tel" class="form-control" id="phone" placeholder="978-000-0000">
                </div>
                <div class="col-lg-5 col-sm-12 pt-2">
                    <label for="selectt" class="form-label">Service Request Type</label>
                    <select #service class="form-select form-control" aria-label="Default select example" d="selectt">
                        <option selected>Make A Selection</option>
                        <option value="1">Spring / Fall Clean-up</option>
                        <option value="2">Weekly Maintenance</option>
                        <option value="3">Landscaping Design</option>
                        <option value="3">Other</option>
                    </select>
                </div>
            </div>
            <div class="my-3">
                <label for="exampleFormControlTextarea1" class="form-label">Describe Request</label>
                <textarea #message class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>

            <div>
                <button (click)="submitContactForm(name, email, phone, service, message)" type="button"
                    class="btn btn-light btn-block" *ngIf="!FormIsProcessing">Submit Request</button>
                <button type="button" class="btn btn-light btn-block" *ngIf="FormIsProcessing" disabled>
                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div> Submit Request
                </button>
                <p class="text-danger" style="font-size:.8rem" *ngIf="ShowErrorMessage"><strong>Error Proccessing
                        Request:</strong> Please complete the form or Call {{app_settings.adminPhone}}</p>
            </div>

        </div>
    </div>



    <app-recent-work-bar></app-recent-work-bar>

</main>
