import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var require: any
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  private _showFormErrorMessage : boolean = false;
  public app_settings = require("../../../assets/AppSettings.json");
  public static RecentWorkListingURLRoutes : string[] = ["spring-and-fall-clean-up", "weekly-maintenance", "landscape-design", "shrub-prunning"]

  constructor(private http : HttpClient, private router: Router) { }

  get ShowErrorMessage() : boolean {
    return this._showFormErrorMessage
  }

  ngOnInit(): void {  }

  submitContactForm(name : HTMLInputElement, email : HTMLInputElement, phone : HTMLInputElement, service : HTMLSelectElement, message : HTMLTextAreaElement){
    if((!name.value || name.value.length  < 1 ) || (!email.value 
      || email.value.length  < 1 ) || (!phone.value || phone.value.length  < 1  ))
      {
        this._showFormErrorMessage = true
        return;
      }else
        this._showFormErrorMessage = false;

    let request = {
      type : "contact_form",
      source: this.app_settings.domain,
      ip: "192.143.1.23",
      sendToEmail: [this.app_settings.adminEmail],
      sendBccTo: [],
      contact: {
        firstName : name.value,
        email : email.value,
        phone : phone.value,
        company: ""
      },
      form : {
       isSensitive : false,
       fields : {
           message : message.value,
           service: service.value
       }
      }
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post(this.app_settings.notification_endpoint, request, httpOptions).subscribe(data => {
      console.log(data)
      this.router.navigateByUrl('/thanks')
    })
    
  }

}
