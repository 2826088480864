<div class="container-fluid text-white pt-3" style="background-color: #327247;">
  <div class="row justify-content-between text-right">
    <div class="col">
      <h6 class="d-inline navlink" style="margin-right: 10px"><a [routerLink]="''">HOME</a></h6>
      <h6 class="d-inline navlink" style="margin-right: 10px"><a [routerLink]="['recent-work']">RECENT WORK</a></h6>
      <h6 class="d-inline navlink" style="margin-right: 10px"><a [routerLink]="['/request-a-quote']">REQUEST QUOTE</a></h6>

    </div>
    <div class="col-lg-3 col-sm-12 float-right pt-4">
      <h4 style="font-weight: 700;">CONTACT US </h4>
      <span class="d-block" style="font-weight: 300;"> 2322 Methuen, 01844 MA</span>
      <span class="d-block navlink" style="font-weight: 300;"><a href="tel:9788843281"> (978) 884-3281</a></span>
    </div>
  </div>
  <div class="row justify-content-end mt-4">
    <div class="col-lg-3 col-sm-12">
      <h5 style="font-weight: 700;">FOLLOW US</h5>
      <span class="d-inline pr-4 navlink" style="font-weight: 300; font-size: .8rem;"><a
          href="https://www.facebook.com/JReyesLandscaping/" target="_blank">FACEBOOK</a></span>
    </div>
  </div>
  <hr />
  <div class="row mt-4 pb-4">
    <div class="col text-center" style="font-weight: 300; font-size: .8rem;">
      Copyright © 2016-2021, J. Reyes Landscaping, LLC. All Rights Reserved. Website By <a
        href="https://anthonyliriano.com" target="_blank" style="text-decoration: none;color: gold;">anthonyliriano.com</a>. |
      Privacy Policy
    </div>
  </div>
</div>