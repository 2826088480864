<div class="container">
    
    <div class="row justify-content-center" style="margin-top: 100px;">
        <div class="col-12 mb-4" style="padding-left: 0px;">
          <h3 style="font-weight: 700 !important;border-bottom:4px solid #dee2e6;padding-left: 0pc;">
            RECENT WORK
          </h3>
          <div class="row" style="padding-left: 10px;">
            <div class="col-lg-3 col-sm-12" style="padding: 0 8px 0 0">
              <div class="card">
                <img class="card-img-top" src="../../../assets/images/58.jpg" alt="Card image cap">
                <div class="card-body py-0 px-1">
                  <span style="font-weight: 500;">Spring & Fall Clean-up.</span>
                  <p style="font-size: .75rem;font-weight: 300">During spring cleanups, our team will go through your yard and prepare it for planting season.</p>
                  <a routerLink="/recent-work/{{RecentWorkListingURLRoutes[0]}}"  class="card-link">View Job Details</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-sm-12" style="padding: 0 8px 0 0">
              <div class="card">
                <img class="card-img-top" src="../../../assets/images/114.jpg" alt="Card image cap">
                <div class="card-body py-0 px-1">
                  <span style="font-weight: 500;">Weekly Maintenance</span>
                  <p style="font-size: .75rem;font-weight: 300">Weekly maintenance of your landscape can ensure that your planting and hardscape remain healthy and beautiful.</p>
                  <a routerLink="/recent-work/{{RecentWorkListingURLRoutes[1]}}" class="card-link">View Job Details</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-sm-12" style="padding: 0 8px 0 0">
              <div class="card">
                <img class="card-img-top" src="../../../assets/images/153.jpg" alt="Card image cap">
                <div class="card-body py-0 px-1">
                  <span style="font-weight: 500;">Landscaping Design</span>
                  <p style="font-size: .75rem;font-weight: 300">With over 20 years of experience, our company has the background, top of the line equipment to make your dreams a reality. </p>
                  <a routerLink="/recent-work/{{RecentWorkListingURLRoutes[2]}}" class="card-link">View Job Details</a>
                </div>
              </div>
            </div>
  
            <div class="col-lg-3 col-sm-12" style="padding: 0 8px 0 0">
              <div class="card">
                <img class="card-img-top" src="../../../assets/images/134.jpg" alt="Card image cap">
                <div class="card-body py-0 px-1">
                  <span style="font-weight: 500;">Shrub Pruning.</span>
                  <p style="font-size: .75rem;font-weight: 300">The best way to ensure that your trees remain healthy, full, and radiant, is shrub prunning performed by an expert team.</p>
                  <a routerLink="/recent-work/{{RecentWorkListingURLRoutes[3]}}" class="card-link">View Job Details</a>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
</div>