<nav class="navbar navbar-expand-md navbar-dark navbar-custom">
    <div class="container">
      <a class="navbar-brand" href="/">J. Reyes Landscaping</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a class="nav-link " [routerLink]="''" >Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['recent-work']"  routerLinkActive="active-link">Recent Work</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/request-a-quote']"  routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true, match:['request-a-quote']}">Request A Quote</a>
          </li>
        </ul>
      </div>
      <span class="text-white navlink"><a href="tel:9788843281" style="font-size: 1.3rem;font-weight:300">Call (978)
          884-3281</a></span>
    </div>
  </nav>

  <div class="container-fluid mx-0 px-0 mb-3">
    <div class="row">
      <div class="col">
        <div id="social">
          <div class="container">
            <div class="row justify-content-end" style="padding-right: 20px;">
              <div class="col-2">
                <ul class="mb-0">
                  <a href="https://www.facebook.com/JReyesLandscaping/" target="_blank"><i
                      class="fa fa-facebook-square fa-2x" style="color: #3b5998;"></i></a>
                  <!-- <i class="fab  instagram fa-instagram fa-2x" ></i> -->
                  <!-- <li><a target="_blank" class="fa fa-facebook" href="https://www.facebook.com/JReyesLandscaping"></a></li> -->
                  <!--  <li><a target="_blank" class="google" href="http://plus.google.com">Google +</a></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div><!-- end #social -->
      </div>
    </div>
  </div>