
  <main class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11 col-sm-12 px-0">
        <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
          <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <a href="/"><img src="../../../assets/images/spring-banner.png" class="d-block w-100" alt="..."></a>
            </div>
            <!-- <div class="carousel-item" data-bs-interval="2000">
            <img src="https://jreyeslandscapingma.com/img/jr/20.jpg" class="d-block w-100" alt="..." height="400">
          </div> -->
          </div>
          <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"  data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"  data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
        </div>
      </div>
    </div>

    <div class="row justify-content-center my-4">
      <div class="col-lg-6 col-sm-12">
        <h3 style="font-weight: 700 !important;border-bottom:4px solid #dee2e6;"> OVER 20 YEARS OF EXPERIENCE</h3>
        <p style="font-weight: 300; font-size: .95rem;">
          Whether you need hedge trimming in the summer or help during the fall, J. Reyes Landscaping in Essex County,
          MA has the skill and experience to get your job done. Regardless of what season it is, J. Reyes Landscaping
          will see to it that your home will look as beautiful as possible. We know that your property is your biggest
          investment, and we vow that any service you hire J. Reyes to complete will enhance the value of your home.
        </p>
        <div class="row">
          <h4>Services</h4>
          <div class="col">
            <ul class="services" style="padding-left: 20px; font-size: .9rem; font-weight: 300;">
              <li>Spring & Fall Cleanups</li>
              <li>Detaching</li>
              <li>Sod Jobs</li>
              <li>Loam</li>
              <li>Seeding</li>
              <li>Shrub Pruning</li>
              <li>Hedge Trimming</li>
              <li>Brush Clearing</li>
            </ul>
          </div>
          <div class="col">
            <ul class="services" style="font-size: .9rem;font-weight: 300">
              <li>Weekly Maintenance</li>
              <li>Landscaping Design</li>
              <li>Planting</li>
              <li>Bark Mulch</li>
              <li>Aerating</li>
              <li>Weeding</li>
              <li>And More!</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <h4>Locations We Service</h4>
              <div class="col-5">
                <ul class="services" style="padding-left: 20px; font-size: .9rem;font-weight: 300">
                  <li>Wyndham</li>
                  <li>Methuen</li>
                  <li>Lawrence</li>
                  <li>Dracut</li>
                  <li>Bradford</li>
                  <li>New Hampshire</li>
                </ul>
              </div>
              <div class="col">
                <ul class="services" style="font-size: .9rem;font-weight: 300">
                  <li>N. Andover</li>
                  <li>Andover</li>
                  <li>Middleton</li>
                  <li>N. Reading</li>
                  <li>Reading</li>
                  <li>Middleton</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12 py-3 h-50" style="background-color: #DBE6DF;">
        <h5 class="text-center">REQUEST A QUOTE</h5>
        <p style="font-size: .9rem;font-weight: 300">
          Contact us via the contact form to find out how we can help meet your landscape needs.
        </p>
        
        
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input #name type="text" class="form-control" id="name">
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input #email type="email" class="form-control" id="email" placeholder="name@example.com">
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">Phone Number</label>
          <input #phone type="tel" class="form-control" id="phone" placeholder="978-000-0000">
        </div>
        <div class="mb-3">
          <label for="selectt" class="form-label">Service Request Type</label>
          <select #service class="form-select form-control" aria-label="Default select example" d="selectt">
            <option selected>Make A Selection</option>
            <option value="1">Spring / Fall Clean-up</option>
            <option value="2">Weekly Maintenance</option>
            <option value="3">Landscaping Design</option>
            <option value="3">Other</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">Describe Request</label>
          <textarea #message class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>

        <div>
          <button (click)="submitContactForm(name, email, phone, service, message)" type="button" class="btn btn-light btn-block mb-1">Submit Request</button>
          <p class="text-danger" style="font-size:.8rem" *ngIf="ShowErrorMessage"><strong>Error Proccessing Request:</strong>  Please complete the form or Call {{app_settings.adminPhone}}</p>
        </div>


      </div>
    </div>

    <app-recent-work-bar></app-recent-work-bar>
  </main>

