import { Component, OnInit } from '@angular/core';

declare var require: any

@Component({
  selector: 'app-recent-work',
  templateUrl: './recent-work.component.html',
  styleUrls: ['./recent-work.component.css']
})
export class RecentWorkComponent implements OnInit {

  private _allGalleryImages : any[] = require('../../api-responses/recent-work-gallery.json');

  constructor() { }

  ngOnInit(): void { }

  public get AllGalleryImages() {
    return this._allGalleryImages;
  }

}
