import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var require: any

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private http : HttpClient, private router: Router) { }
  public app_settings = require("../../../assets/AppSettings.json");
  private _showFormErrorMessage : boolean = false;
  public FormIsProcessing : boolean;

  ngOnInit(): void {
  }

  get ShowErrorMessage() : boolean {
    return this._showFormErrorMessage
  }

  submitContactForm(name : HTMLInputElement, email : HTMLInputElement, phone : HTMLInputElement, service : HTMLSelectElement, message : HTMLTextAreaElement){
    if((!name.value || name.value.length  < 1 ) || (!email.value 
      || email.value.length  < 1 ) || (!phone.value || phone.value.length  < 1  ))
      {
        this._showFormErrorMessage = true
        return;
      }else
        this._showFormErrorMessage = false;

    let request = {
      type : "contact_form",
      source: this.app_settings.domain,
      ip: "192.143.1.23",
      sendToEmail: [this.app_settings.adminEmail],
      sendBccTo: [],
      contact: {
        firstName : name.value,
        email : email.value,
        phone : phone.value,
        company: ""
      },
      form : {
       isSensitive : false,
       fields : {
           message : message.value,
           service: service.value
       }
      }
    }

    
    this.FormIsProcessing = true;
    this.http.post(this.app_settings.notification_endpoint, request).subscribe(data => {
      console.log(data)
      this.FormIsProcessing = false;
      this.router.navigateByUrl('/thanks')
    })
    
  }

}
