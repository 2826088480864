import { Component, OnInit } from '@angular/core';

declare var require: any
@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

  public app_settings = require("../../../assets/AppSettings.json");

  constructor() { }

  ngOnInit(): void {
  }

}
