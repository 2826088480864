<main class="container mt-1">
  <!-- External Code Page Content -->
  <h1 style="font-weight: 700 !important;border-bottom:4px solid #dee2e6;" class=" text-center text-lg-left">J. REYES
    LANDSCAPING IN ACTION</h1>

  <div class="row text-center">



    <div class="col mt-3">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-bs-touch="true">

        <div class="carousel-inner">
          <div class="carousel-item " *ngFor="let img of AllGalleryImages;first as isFirst" data-bs-interval="1500"
            [class]="isFirst ? 'carousel-item active' : 'carousel-item '">
            <img src="{{img.ImageURL}}">
          </div>

        </div>
        <div class="controls">
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

  </div>
  <!-- External Code/.container -->


  <app-recent-work-bar></app-recent-work-bar>
</main>
