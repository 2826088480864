
    <!-- Job Descriptions -->
    <main class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="row justify-content-between " style="border-bottom:4px solid #dee2e6;margin-bottom: 0;">
                    <div class="col-lg-6 col-sm-12 px-0">
                        <h3 class="m-1 p-0" style="text-transform: uppercase;">
                            {{ActiveListing.JobName}}
                        </h3>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="text-end align-bottom py-2">
                            <a routerLink="/request-a-quote" style=" font-weight: 500;text-decoration: none;font-size: 1.2rem;">
                                REQUEST A QUOTE
                            </a>
                        </div>
                    </div>
                </div>
                <p class="mt-2" style="font-weight: 400;">
                    {{ActiveListing.JobDescription}}
                </p>
            </div>
        </div>
        <div class="row justify-content-center my-4" *ngFor="let category of ActiveListing.JobCategories; index as index">
            <div class="col-lg-4 col-sm-12" style="padding:0px">
                <img id="main-image-{{index}}" src="{{category.MainImageURL}}" class="img-thumbnail" alt="...">
            </div>
            <div class="col-lg-8 col-sm-12 pt-2 pl-4">
                <h4 style="text-transform: uppercase;">{{category.Name}}</h4>
                <p style="font-weight: 400; font-size:.85rem;">{{category.Description}} </p>
                    <div class="row mt-2" style="margin-left: 5px;">
                        <div class="col-2 p-1 m-0" *ngFor="let image of category.AllImages">
                            <img src="{{image}}" class="img-thumbnail p-0" (click)="ChangeMainImage('main-image-' + index, image)" >
                        </div>
                    </div>
            </div>
        </div>
    </main>

   <app-recent-work-bar></app-recent-work-bar>

    